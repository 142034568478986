/* eslint-disable camelcase */
import { Collapse } from 'antd'
import Loading from 'components/loading'
import { Options } from 'components/select'
import { FormikProps, useFormik } from 'formik'
import { sortBy } from 'lodash'
import { ROLE_TYPE, useAuth } from 'modules/auth/context/useAuth'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { numberFormater } from 'utils/numberFormater'
import { ORDER_TYPE } from 'utils/orderType'
import { PRICING_STATUS } from 'utils/pricingStatus'
import GeneralPricing from '../components/GeneralPricing'
import OptionPricingComponent from '../components/OptionPricingComponent'
import PricingOverview from '../components/PricingOverview'
import PurchasePriceCalculation from '../components/PurchasePriceCalculation'
import SiteSurveyNotConnected from '../components/SiteSurveyNotConnectedForm'
import { ISavePricing, PricingQueries } from '../queries/pricing'
import { useQuoteLayout } from '../QuoteLayout'

export interface IResidualValue {
    durationOfContract: number
    standardResidualValue: number
    totalResidualValue: number
    serviceBigTruckAllIn: number
    serviceBigTruckPm: number
    servicePriceTriesPerHour: number
    exSolutionServicePrice: number
    filledInsuranceCost: number
    filledPreventiveMaintenance: number
    factor: number
    financialPart: number
    insuranceCost: number
    serviceAllIn: {
        basic_truck: number
        service_battery: number
        service_ex_solutions: number
        service_extra_attachments: number
        service_extra_preplist: number
        service_extra_special: number
        service_tyre: number
        total_service: number
    }
    servicePm: {
        batteries_service_cost: number
        preventive_maintenance: number
        total_price_per_year_ex_bmwt: number
        total_price_per_year_inclusive_bmwt: number
        bmwt_battery: number
    }
    error: string
    errorKey: string
    leasePriceOptions: any
}

interface IPricingValue {
    error: boolean
    adjustable: boolean
    useTable: boolean
}

const initialPricingValue: IPricingValue = {
    error: false,
    adjustable: false,
    useTable: false,
}

interface IPricingError {
    totalQuoteValue: IPricingValue
    margin: IPricingValue
    residualValue: IPricingValue
    vpg: IPricingValue
    duration: IPricingValue
    leaseFactor: IPricingValue
    category: IPricingValue
    discountPm: IPricingValue
    discountAllIn: IPricingValue
}

export interface IPricingForm {
    machineListPrice: number
    totalNetValueParts: number
    totalOptionsSelected: number
    financing: string
    showAllPriceSeparate: string
    maintenance: string
    return: string
    insurance: string
    returnDescription: string
    siteSurveyId: number
    investmentAndFactor: number
    runningHours: number
    isElectric: boolean
    additionalResidualPercentage: number
    totalResidualValue: number
    deployment: string
    discountServiceAllInPercentage: number
    discountServicePreventiveMaintenancePercentage: number
    residualValue: IResidualValue[]
    selectedDurationOfContract?: number
    totalCostPrice: number
    specialPriceRequestPercentage: number
    standardDiscountRate: number
    discountAmount: number
    totalCostPriceIncludingDiscount: number
    useQuotePriceMinusTradeInForCalculation: boolean
    vpg: number
    vpgAmount: number
    dataLogger: number
    totalExtra: number
    totalOptions: number
    marginForOption: number
    netAllCost: number
    profitPercentage: number
    profitAmount: number
    bridgingMachineAmount: number
    financialSupportAmount: number
    quotePrice: number
    salesPrice: number | null
    salesProfitPercent: number
    showManualSalesPrice: boolean
    investmentGroup: number
    category: number
    numberOfBatteries: number
    showTradeIn: false
    tradeInValue: number
    quoteMinusTradeIn: number
    addTyre: boolean
    isWhe: boolean
    tyreType: string
    addBattery: boolean
    requestFleet: boolean
    batteryService: number
    addAttachment: boolean
    addSpecials: boolean
    addExSolution: boolean
    bmwt: boolean
    bmwtBattery: boolean
    pricingError: IPricingError
    isFleetRequested?: boolean
    feedback: string
    tradeInValueTextField: string
    show_trade_in_value_text_field?: boolean
}

export const initialResidualValue: IResidualValue = {
    durationOfContract: 0,
    standardResidualValue: 0,
    totalResidualValue: 0,
    serviceBigTruckAllIn: 0,
    serviceBigTruckPm: 0,
    exSolutionServicePrice: 0,
    filledInsuranceCost: 0,
    filledPreventiveMaintenance: 0,
    factor: 0,
    financialPart: 0,
    insuranceCost: 0,
    serviceAllIn: {
        basic_truck: 0,
        service_battery: 0,
        service_ex_solutions: 0,
        service_extra_attachments: 0,
        service_extra_preplist: 0,
        service_extra_special: 0,
        service_tyre: 0,
        total_service: 0,
    },
    servicePm: {
        batteries_service_cost: 0,
        preventive_maintenance: 0,
        total_price_per_year_ex_bmwt: 0,
        total_price_per_year_inclusive_bmwt: 0,
        bmwt_battery: 0,
    },
    error: '',
    servicePriceTriesPerHour: 0,
    errorKey: '',
    leasePriceOptions: '',
}

const Pricing = () => {
    const { t } = useTranslation()
    const { quoteDetail, recalculateQuoteMutation, isRefetching } = useQuoteLayout()
    const { configurationId } = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const queryClient = useQueryClient()
    const [isInitialFetched, setIsInitialFetched] = useState<boolean>(false)
    const [isPricingFetched, setIsPricingFetched] = useState<boolean>(false)
    const [isAdditionalDataFetched, setIsAdditionalDataFetched] = useState<boolean>(false)
    const pricingStatus = searchParams.get('pricing_status')
    const { Panel } = Collapse
    const { authorizationMatrix, userExtra } = useAuth()
    const [configurationPriceId, setConfigurationPriceId] = useState<number>(0)
    const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false)
    const [isSaveSiteSurveyLoading, setIsSaveSiteSurveyLoading] = useState<boolean>(false)
    const [referenceResidualValue, setReferenceResidualValue] = useState<any>()
    const [isPmPlusAvailable, setIsPmPlusAvailable] = useState<boolean>(false)

    const [extraPricing, setExtraPricing] = useState<any>({
        attachment: [],
        batteries: [],
        batteryAccessories: [],
        chargers: [],
        exSolution: [],
        prepList: [],
        special: [],
        hysterBatteries: [],
    })
    const [partData, setPartData] = useState<any[]>([])
    const [options, setOptions] = useState<any[]>([])
    const [isBigTruck, setIsBigTruck] = useState<boolean>(false)
    const [investGroupOptions, setInvestmentGroupOptions] = useState<Options[]>([])
    const [optionalAttachment, setOptionalAttachment] = useState<any[]>([])
    const [optionalBatteries, setOptionalBatteries] = useState<any[]>([])
    const [optionalBatteryAccessories, setOptionalBatteryAccessories] = useState<any[]>([])
    const [optionalChargers, setOptionalChargers] = useState<any[]>([])
    const [optionalExSolution, setOptionalExSolution] = useState<any[]>([])
    const [optionalPrepList, setOptionalPrepList] = useState<any[]>([])
    const [optionalHysterBatteries, setOptionalHysterBatteries] = useState<any[]>([])
    const [optionalSpecial, setOptionalSpecial] = useState<any[]>([])
    const [monthlyConfigurationPrice, setInitialConfigurationMonths] = useState<any[]>([])
    const [isRequestFleet, setIsRequestFleet] = useState<boolean>(false)
    const [requestFleetRemark, setRequestFleetRemark] = useState<string>('')

    const calculateTotalPrice = (extraItems: any[]) => {
        let totalPrice = 0
        if (extraItems && extraItems.length > 0) {
            extraItems.forEach(extraItem => {
                totalPrice += parseFloat(String(extraItem.total_price))
            })
        }
        return parseFloat(String(totalPrice))
    }

    const totalPrepList = calculateTotalPrice(extraPricing.prepList)
    const totalChargers = calculateTotalPrice(extraPricing.chargers)
    const totalBatteries = calculateTotalPrice(extraPricing.batteries)
    const totalBatteryAccessories = calculateTotalPrice(extraPricing.batteryAccessories)
    const totalAttachments = calculateTotalPrice(extraPricing.attachment)
    const totalExSolutions = calculateTotalPrice(extraPricing.exSolution)
    const totalSpecial = calculateTotalPrice(extraPricing.special)
    const totalHysterBattery = calculateTotalPrice(extraPricing.hysterBatteries)
    const totalExtra =
        totalPrepList +
        totalChargers +
        totalBatteries +
        totalBatteryAccessories +
        totalAttachments +
        totalExSolutions +
        totalSpecial +
        totalHysterBattery

    const requestFleetMutation = useMutation(
        ['requestFleetMutation'],
        (priceId: number) =>
            PricingQueries.requestFleet(
                parseInt(String(configurationId), 10),
                priceId || 0,
                requestFleetRemark,
            ),
        {
            onSuccess: data => {
                setSearchParams({ pricing_status: String(data.data.data.status) })
                toast.success(data?.data?.message || t('message.request_fleet_success'))
                queryClient.invalidateQueries({ queryKey: ['quoteDetail'] })
            },
            onError: (error: any) => {
                toast.error(error?.message || t('message.request_fleet_failed'))
            },
        },
    )

    const requestForApproval = useMutation(
        ['requestForApproval'],
        (priceId: number) =>
            PricingQueries.requestApproval(parseInt(String(configurationId), 10), priceId || 0),
        {
            onSuccess: data => {
                toast.success(data?.data?.message || t('message.request_approval_success'))
                queryClient.invalidateQueries({ queryKey: ['quoteDetail'] })
            },
            onError: (error: any) => {
                toast.error(error?.message || t('message.request_approval_failed'))
            },
        },
    )

    const savePricing = useMutation(
        ['savePricing'],
        (props: {
            pricingForm: ISavePricing
            isRequestFleet: boolean
            isRequestApproval: boolean
        }) => PricingQueries.savePricing(props.pricingForm, parseInt(String(configurationId), 10)),
        {
            onSuccess: (data, props) => {
                setSearchParams({ pricing_status: String(data.data.data.status) })
                if (props.isRequestFleet) {
                    requestFleetMutation.mutate(data?.data?.data?.id)
                } else if (props.isRequestApproval) {
                    requestForApproval.mutate(data?.data?.data?.id)
                } else {
                    toast.success(data?.data?.message || t('message.save_pricing_success'))
                    queryClient.invalidateQueries({ queryKey: ['quoteDetail'] })
                }
                setIsRequestFleet(false)
                setIsSubmitLoading(false)
            },
            onError: (error: any) => {
                toast.error(error?.message || t('message.save_pricing_failed'))
                setIsRequestFleet(false)
            },
        },
    )

    const formik: FormikProps<IPricingForm> = useFormik<IPricingForm>({
        initialValues: {
            // general
            financing: '',
            showAllPriceSeparate: '',
            maintenance: '',
            return: '',
            insurance: '',
            returnDescription: '',
            siteSurveyId: 0,
            isElectric: false,
            // condition
            investmentAndFactor: 0,
            runningHours: 0,
            // conditions lease
            additionalResidualPercentage: 0,
            totalResidualValue: 0,
            investmentGroup: 0,
            // conditions service
            deployment: '',
            discountServiceAllInPercentage: 0,
            discountServicePreventiveMaintenancePercentage: 0,
            // purchase price calculation
            residualValue: [initialResidualValue],
            selectedDurationOfContract: undefined,
            totalCostPrice: 0,
            specialPriceRequestPercentage: 0,
            standardDiscountRate: 0,
            discountAmount: 0,
            totalCostPriceIncludingDiscount: 0,
            // purchase price calculation
            vpg: 0,
            vpgAmount: 0,
            dataLogger: 0,
            totalExtra: 0,
            totalOptions: 0,
            // as-per-requirement-of-client
            marginForOption: 10,
            netAllCost: 0,
            profitPercentage: 10,
            profitAmount: 0,
            bridgingMachineAmount: 0,
            financialSupportAmount: 0,
            quotePrice: 0,
            salesPrice: null,
            salesProfitPercent: 0,
            showManualSalesPrice: false,
            category: 0,
            numberOfBatteries: 0,
            showTradeIn: false,
            tradeInValue: 0,
            quoteMinusTradeIn: 0,
            tradeInValueTextField: '',
            useQuotePriceMinusTradeInForCalculation: false,
            addTyre: false,
            tyreType: 'single_std',
            isWhe: false,
            addBattery: false,
            addExSolution: false,
            batteryService: 0,
            addAttachment: false,
            addSpecials: false,
            requestFleet: false,
            bmwt: false,
            bmwtBattery: false,
            machineListPrice: 0,
            totalNetValueParts: 0,
            totalOptionsSelected: 0,
            pricingError: {
                category: initialPricingValue,
                discountPm: initialPricingValue,
                discountAllIn: initialPricingValue,
                duration: initialPricingValue,
                leaseFactor: initialPricingValue,
                margin: initialPricingValue,
                residualValue: initialPricingValue,
                totalQuoteValue: initialPricingValue,
                vpg: initialPricingValue,
            },
            feedback: '',
            // lease: '',
        },
        onSubmit: (values: IPricingForm) => {
            const months: number[] = []
            values.residualValue.forEach((value: IResidualValue) => {
                if (value.durationOfContract !== 0) {
                    months.push(value.durationOfContract)
                }
            })
            if (!values.residualValue.some(item => item.durationOfContract === 0)) {
                savePricing.mutate({
                    pricingForm: {
                        novo_list_price: values.totalCostPrice,
                        machine_list_price: values.machineListPrice,
                        total_net_value_parts: values.totalNetValueParts,
                        total_options_selected: values.totalOptionsSelected || 0,
                        special_price_request_percentage: values.specialPriceRequestPercentage,
                        discount_amount: values.discountAmount,
                        total_extra: values.totalExtra,
                        total_options: values.totalOptions,
                        vpg_amount: values.vpgAmount,
                        vpg_percentage: values.vpg,
                        net_all_cost: values.netAllCost,
                        novo_quote_price: values.quotePrice,
                        min_investment_group: values.investmentGroup || 0,
                        running_hours: values.runningHours || 0,
                        category: values.category,
                        novo_configuration_id: parseInt(String(configurationId), 10),
                        standard_discount_percentage: values.standardDiscountRate,
                        bridging_machine_amount: values.bridgingMachineAmount,
                        financial_support_amount: values.financialSupportAmount,
                        data_logger: values.dataLogger,
                        profit_percentage: values.profitPercentage,
                        profit_amount: values.profitAmount,
                        manual_price: values.salesPrice,
                        trade_in_value: values.tradeInValue,
                        trade_in_value_text_field: values.tradeInValueTextField,
                        show_trade_in_value_text_field: values.show_trade_in_value_text_field,
                        use_quote_price_minus_trade_in_for_calculation:
                            values.useQuotePriceMinusTradeInForCalculation,
                        quote_minus_trade_in: values.quoteMinusTradeIn,
                        additional_residual_percentage: values.additionalResidualPercentage,
                        number_of_batteries: values.numberOfBatteries,
                        add_tyre: values.addTyre,
                        add_battery: values.addBattery,
                        add_attachment: values.addAttachment,
                        discount_service_all_in: values.discountServiceAllInPercentage,
                        discount_service_pm: values.discountServicePreventiveMaintenancePercentage,
                        add_specials: values.addSpecials,
                        add_ex_solutions: values.addExSolution,
                        request_fleet: values.requestFleet,
                        battery_service: values.batteryService,
                        bmwt: values.bmwt,
                        add_bmwt_battery: values.bmwtBattery,
                        is_whe: values.isWhe || false,
                        margin_for_options: values.marginForOption,
                        selected_duration_of_contract:
                            values.selectedDurationOfContract ||
                            values.residualValue[0].durationOfContract,
                        type_banden: values.tyreType,
                        monthly_configuration_prices: values.residualValue.map(residualValue => ({
                            duration_of_contract: residualValue.durationOfContract,
                            total_residual_value: residualValue.totalResidualValue,
                            standard_residual_value: residualValue.standardResidualValue,
                            lease_price_options: residualValue.leasePriceOptions,
                            lease_factor: residualValue.factor,
                            financial_part: residualValue.financialPart,
                            insurance_cost: residualValue.insuranceCost,
                            basic_truck: residualValue.serviceAllIn.basic_truck,
                            service_big_truck_tyre_per_hour: residualValue.servicePriceTriesPerHour,
                            ex_solution_service_price: residualValue.exSolutionServicePrice,
                            filled_insurance_rate: residualValue.filledInsuranceCost,
                            filled_preventive_maintenance:
                                residualValue.filledPreventiveMaintenance,
                            service_big_truck_all_in: residualValue.serviceBigTruckAllIn,
                            service_big_truck_pm: residualValue.serviceBigTruckPm,
                            service_extra_preplist:
                                residualValue?.serviceAllIn.service_extra_preplist,
                            service_extra_special:
                                residualValue?.serviceAllIn.service_extra_special,
                            service_extra_attachments:
                                residualValue?.serviceAllIn.service_extra_attachments,
                            total_service: residualValue?.serviceAllIn.total_service,
                            service_battery: residualValue?.serviceAllIn.service_battery,
                            service_tyre: residualValue.serviceAllIn.service_tyre,
                            total_price_per_year_ex_bmwt:
                                residualValue?.servicePm?.total_price_per_year_ex_bmwt,
                            total_price_per_year_inclusive_bmwt:
                                residualValue?.servicePm?.total_price_per_year_inclusive_bmwt,
                            preventive_maintenance:
                                residualValue?.servicePm?.preventive_maintenance,
                            batteries_service_cost: residualValue.servicePm.batteries_service_cost,
                            bmwt_battery: residualValue.servicePm.bmwt_battery,
                        })),
                    },
                    isRequestFleet: false,
                    isRequestApproval: false,
                })
            } else {
                toast.error('message.duration_of_contract_cannot_be_0')
            }
        },
    })

    useEffect(() => {
        if (totalExtra) {
            formik.setFieldValue('totalExtra', totalExtra)
        }
    }, [totalExtra])

    const handleRequestFleet = () => {
        if (!formik.values.residualValue.some(item => item.durationOfContract === 0)) {
            setIsRequestFleet(true)
            savePricing.mutate({
                pricingForm: {
                    novo_list_price: formik.values.totalCostPrice,
                    machine_list_price: formik.values.machineListPrice,
                    total_net_value_parts: formik.values.totalNetValueParts,
                    total_options_selected: formik.values.totalOptionsSelected || 0,
                    special_price_request_percentage: formik.values.specialPriceRequestPercentage,
                    discount_amount: formik.values.discountAmount,
                    total_extra: formik.values.totalExtra,
                    total_options: formik.values.totalOptions,
                    vpg_amount: formik.values.vpgAmount,
                    vpg_percentage: formik.values.vpg,
                    net_all_cost: formik.values.netAllCost,
                    novo_quote_price: formik.values.quotePrice,
                    min_investment_group: formik.values.investmentGroup || 0,
                    running_hours: formik.values.runningHours || 0,
                    category: formik.values.category,
                    novo_configuration_id: parseInt(String(configurationId), 10),
                    standard_discount_percentage: formik.values.standardDiscountRate,
                    bridging_machine_amount: formik.values.bridgingMachineAmount,
                    financial_support_amount: formik.values.financialSupportAmount,
                    data_logger: formik.values.dataLogger,
                    profit_percentage: formik.values.profitPercentage,
                    profit_amount: formik.values.profitAmount,
                    manual_price: formik.values.salesPrice,
                    trade_in_value: formik.values.tradeInValue,
                    trade_in_value_text_field: formik.values.tradeInValueTextField,
                    show_trade_in_value_text_field: formik.values.show_trade_in_value_text_field,
                    quote_minus_trade_in: formik.values.quoteMinusTradeIn,
                    use_quote_price_minus_trade_in_for_calculation:
                        formik.values.useQuotePriceMinusTradeInForCalculation,
                    additional_residual_percentage: formik.values.additionalResidualPercentage,
                    add_tyre: formik.values.addTyre,
                    add_battery: formik.values.addBattery,
                    add_attachment: formik.values.addAttachment,
                    discount_service_all_in: formik.values.discountServiceAllInPercentage,
                    discount_service_pm:
                        formik.values.discountServicePreventiveMaintenancePercentage,
                    add_specials: formik.values.addSpecials,
                    number_of_batteries: formik.values.numberOfBatteries,
                    add_ex_solutions: formik.values.addExSolution,
                    request_fleet: formik.values.requestFleet,
                    battery_service: formik.values.batteryService,
                    bmwt: formik.values.bmwt,
                    is_whe: formik.values.isWhe || false,
                    add_bmwt_battery: formik.values.bmwtBattery,
                    margin_for_options: formik.values.marginForOption,
                    type_banden: formik.values.tyreType,
                    selected_duration_of_contract:
                        formik.values.selectedDurationOfContract ||
                        formik.values.residualValue[0].durationOfContract,
                    monthly_configuration_prices: formik.values.residualValue.map(
                        residualValue => ({
                            duration_of_contract: residualValue.durationOfContract,
                            total_residual_value: residualValue.totalResidualValue,
                            standard_residual_value: residualValue.standardResidualValue,
                            lease_price_options: residualValue.leasePriceOptions,
                            service_big_truck_tyre_per_hour: residualValue.servicePriceTriesPerHour,
                            ex_solution_service_price: residualValue.exSolutionServicePrice,
                            filled_insurance_rate: residualValue.filledInsuranceCost,
                            filled_preventive_maintenance:
                                residualValue.filledPreventiveMaintenance,
                            lease_factor: residualValue.factor,
                            financial_part: residualValue.financialPart,
                            insurance_cost: residualValue.insuranceCost,
                            basic_truck: residualValue.serviceAllIn.basic_truck,
                            service_big_truck_all_in: residualValue.serviceBigTruckAllIn,
                            service_big_truck_pm: residualValue.serviceBigTruckPm,
                            service_extra_preplist:
                                residualValue?.serviceAllIn.service_extra_preplist,
                            service_extra_special:
                                residualValue?.serviceAllIn.service_extra_special,
                            service_extra_attachments:
                                residualValue?.serviceAllIn.service_extra_attachments,
                            service_battery: residualValue?.serviceAllIn.service_battery,
                            service_tyre: residualValue.serviceAllIn.service_tyre,
                            total_service: residualValue?.serviceAllIn.total_service,
                            total_price_per_year_ex_bmwt:
                                residualValue?.servicePm?.total_price_per_year_ex_bmwt,
                            total_price_per_year_inclusive_bmwt:
                                residualValue?.servicePm?.total_price_per_year_inclusive_bmwt,
                            preventive_maintenance:
                                residualValue?.servicePm?.preventive_maintenance,
                            batteries_service_cost: residualValue.servicePm.batteries_service_cost,
                            bmwt_battery: residualValue.servicePm.bmwt_battery,
                        }),
                    ),
                },
                isRequestFleet: true,
                isRequestApproval: false,
            })
        } else {
            toast.error('message.duration_of_contract_cannot_be_0')
        }
    }

    const handleRequestApproval = () => {
        if (!formik.values.residualValue.some(item => item.durationOfContract === 0)) {
            savePricing.mutate({
                pricingForm: {
                    novo_list_price: formik.values.totalCostPrice,
                    machine_list_price: formik.values.machineListPrice,
                    total_net_value_parts: formik.values.totalNetValueParts,
                    total_options_selected: formik.values.totalOptionsSelected || 0,
                    special_price_request_percentage: formik.values.specialPriceRequestPercentage,
                    discount_amount: formik.values.discountAmount,
                    total_extra: formik.values.totalExtra,
                    total_options: formik.values.totalOptions,
                    vpg_amount: formik.values.vpgAmount,
                    vpg_percentage: formik.values.vpg,
                    net_all_cost: formik.values.netAllCost,
                    novo_quote_price: formik.values.quotePrice,
                    min_investment_group: formik.values.investmentGroup || 0,
                    running_hours: formik.values.runningHours || 0,
                    category: formik.values.category,
                    novo_configuration_id: parseInt(String(configurationId), 10),
                    standard_discount_percentage: formik.values.standardDiscountRate,
                    bridging_machine_amount: formik.values.bridgingMachineAmount,
                    financial_support_amount: formik.values.financialSupportAmount,
                    data_logger: formik.values.dataLogger,
                    profit_percentage: formik.values.profitPercentage,
                    profit_amount: formik.values.profitAmount,
                    manual_price: formik.values.salesPrice,
                    trade_in_value: formik.values.tradeInValue,
                    trade_in_value_text_field: formik.values.tradeInValueTextField,
                    show_trade_in_value_text_field: formik.values.show_trade_in_value_text_field,
                    quote_minus_trade_in: formik.values.quoteMinusTradeIn,
                    use_quote_price_minus_trade_in_for_calculation:
                        formik.values.useQuotePriceMinusTradeInForCalculation,
                    additional_residual_percentage: formik.values.additionalResidualPercentage,
                    add_tyre: formik.values.addTyre,
                    add_battery: formik.values.addBattery,
                    add_attachment: formik.values.addAttachment,
                    request_fleet: formik.values.requestFleet,
                    number_of_batteries: formik.values.numberOfBatteries,
                    discount_service_all_in: formik.values.discountServiceAllInPercentage,
                    discount_service_pm:
                        formik.values.discountServicePreventiveMaintenancePercentage,
                    add_specials: formik.values.addSpecials,
                    add_ex_solutions: formik.values.addExSolution,
                    battery_service: formik.values.batteryService,
                    bmwt: formik.values.bmwt,
                    is_whe: formik.values.isWhe || false,
                    add_bmwt_battery: formik.values.bmwtBattery,
                    margin_for_options: formik.values.marginForOption,
                    type_banden: formik.values.tyreType,
                    selected_duration_of_contract:
                        formik.values.selectedDurationOfContract ||
                        formik.values.residualValue[0].durationOfContract,
                    monthly_configuration_prices: formik.values.residualValue.map(
                        residualValue => ({
                            duration_of_contract: residualValue.durationOfContract,
                            total_residual_value: residualValue.totalResidualValue,
                            standard_residual_value: residualValue.standardResidualValue,
                            lease_price_options: residualValue.leasePriceOptions,
                            service_big_truck_tyre_per_hour: residualValue.servicePriceTriesPerHour,
                            ex_solution_service_price: residualValue.exSolutionServicePrice,
                            filled_insurance_rate: residualValue.filledInsuranceCost,
                            filled_preventive_maintenance:
                                residualValue.filledPreventiveMaintenance,
                            lease_factor: residualValue.factor,
                            financial_part: residualValue.financialPart,
                            insurance_cost: residualValue.insuranceCost,
                            basic_truck: residualValue.serviceAllIn.basic_truck,
                            service_big_truck_all_in: residualValue.serviceBigTruckAllIn,
                            service_big_truck_pm: residualValue.serviceBigTruckPm,
                            service_extra_preplist:
                                residualValue?.serviceAllIn.service_extra_preplist,
                            service_extra_special:
                                residualValue?.serviceAllIn.service_extra_special,
                            service_extra_attachments:
                                residualValue?.serviceAllIn.service_extra_attachments,
                            service_battery: residualValue?.serviceAllIn.service_battery,
                            service_tyre: residualValue.serviceAllIn.service_tyre,
                            total_service: residualValue?.serviceAllIn.total_service,
                            total_price_per_year_ex_bmwt:
                                residualValue?.servicePm?.total_price_per_year_ex_bmwt,
                            total_price_per_year_inclusive_bmwt:
                                residualValue?.servicePm?.total_price_per_year_inclusive_bmwt,
                            preventive_maintenance:
                                residualValue?.servicePm?.preventive_maintenance,
                            batteries_service_cost: residualValue.servicePm.batteries_service_cost,
                            bmwt_battery: residualValue.servicePm.bmwt_battery,
                        }),
                    ),
                },
                isRequestFleet: false,
                isRequestApproval: true,
            })
        } else {
            toast.error('message.duration_of_contract_cannot_be_0')
        }
    }

    useEffect(() => {
        if (formik.values.salesPrice) {
            const profitPercent = formik.values.salesPrice
                ? ((Number(formik.values.salesPrice) - formik.values.netAllCost) /
                      Number(formik.values.salesPrice)) *
                  100
                : 0
            formik.setFieldValue('salesProfitPercent', profitPercent?.toFixed(2))
        }
    }, [formik.values.salesPrice])

    // set error in pricing
    useEffect(() => {
        if (formik.values.quotePrice) {
            const maxQuotePrice = parseFloat(
                authorizationMatrix?.find(
                    (auth: any) => auth.authorization_condition === 'TOTAL_QUOTE_VALUE',
                )?.value,
            )
            if (formik.values.quotePrice > maxQuotePrice) {
                formik.setFieldValue('pricingError', {
                    ...formik.values.pricingError,
                    totalQuoteValue: {
                        error: true,
                        adjustable: true,
                        useTable: false,
                    },
                })
            } else {
                formik.setFieldValue('pricingError', {
                    ...formik.values.pricingError,
                    totalQuoteValue: {
                        error: false,
                        adjustable: true,
                        useTable: false,
                    },
                })
            }
        }
    }, [formik.values.quotePrice])

    useEffect(() => {
        if (!isBigTruck) {
            if (formik.values.maintenance === 'PREVENTIVE_MAINTAINENCE') {
                formik.setFieldValue('bmwt', true)
                formik.setFieldValue('bmwtBattery', true)
                formik.setFieldValue('addTyre', false)
                formik.setFieldValue('addAttachment', false)
                formik.setFieldValue('addSpecials', false)
                formik.setFieldValue('addExSolution', false)
            }
            if (formik.values.maintenance === 'ALL_IN' || formik.values.maintenance === 'N.V.T') {
                formik.setFieldValue('bmwt', false)
                formik.setFieldValue('bmwtBattery', true)
            }
        }
    }, [formik.values.maintenance])

    useEffect(() => {
        if (formik.values.vpg) {
            const vpg = authorizationMatrix?.find(
                (auth: any) => auth.authorization_condition === 'VPG',
            )
            if (vpg) {
                formik.setFieldValue('pricingError', {
                    ...formik.values.pricingError,
                    totalQuoteValue: {
                        error: false,
                        adjustable: vpg?.adjustable,
                        useTable: false,
                    },
                })
            }
        }
    }, [formik.values.vpg])

    useEffect(() => {
        if (formik.values.profitPercentage) {
            const minProfitPercentage = parseFloat(
                authorizationMatrix?.find((auth: any) => auth.authorization_condition === 'MARGIN')
                    ?.min_value,
            )
            if (formik.values.profitPercentage < minProfitPercentage) {
                formik.setFieldValue('pricingError', {
                    ...formik.values.pricingError,
                    margin: {
                        error: true,
                        adjustable: true,
                        useTable: false,
                    },
                })
            } else {
                formik.setFieldValue('pricingError', {
                    ...formik.values.pricingError,
                    margin: {
                        error: false,
                        adjustable: true,
                        useTable: false,
                    },
                })
            }
        }
    }, [formik.values.profitPercentage])

    useEffect(() => {
        const maxValue = authorizationMatrix?.find(
            (auth: any) => auth.authorization_condition === 'RESIDUAL_VALUE',
        )?.max_value
        if (!formik.touched.additionalResidualPercentage) {
            formik.setFieldTouched('additionalResidualPercentage', true)
        }
        if (formik.values.additionalResidualPercentage > parseFloat(maxValue)) {
            const tempPricingError = {
                ...formik.values.pricingError,
                residualValue: {
                    error: true,
                    adjustable: false,
                    useTable: false,
                },
            }
            formik.setFieldValue('pricingError', { ...tempPricingError })
        } else {
            formik.setFieldValue('pricingError', {
                ...formik.values.pricingError,
                residualValue: {
                    error: false,
                    adjustable: false,
                    useTable: false,
                },
            })
        }
    }, [formik.values.additionalResidualPercentage, authorizationMatrix])

    useEffect(() => {
        if (formik.values.residualValue) {
            // duration of contract
            const maxDurationOfContract = parseFloat(
                authorizationMatrix?.find(
                    (auth: any) => auth.authorization_condition === 'DURATION',
                )?.max_value,
            )
            let isDurationOfContractInCorrect = false
            formik.values.residualValue.forEach(value => {
                if (value.durationOfContract > maxDurationOfContract) {
                    isDurationOfContractInCorrect = true
                }
            })
            if (isDurationOfContractInCorrect) {
                formik.setFieldValue('pricingError', {
                    ...formik.values.pricingError,
                    duration: {
                        error: true,
                        adjustable: true,
                        useTable: false,
                    },
                })
            } else {
                formik.setFieldValue('pricingError', {
                    ...formik.values.pricingError,
                    duration: {
                        error: false,
                        adjustable: true,
                        useTable: false,
                    },
                })
            }

            // lease Factor
            const isLeaseFactorUseTable = parseFloat(
                authorizationMatrix?.find(
                    (auth: any) => auth.authorization_condition === 'DURATION',
                )?.use_table,
            )
            if (isLeaseFactorUseTable) {
                formik.setFieldValue('pricingError', {
                    ...formik.values.pricingError,
                    leaseFactor: {
                        error: false,
                        adjustable: false,
                        useTable: true,
                    },
                })
            } else {
                formik.setFieldValue('pricingError', {
                    ...formik.values.pricingError,
                    leaseFactor: {
                        error: false,
                        adjustable: true,
                        useTable: false,
                    },
                })
            }
            // const lease
        }
    }, [formik.values.residualValue, authorizationMatrix])

    useEffect(() => {
        if (formik.values.category) {
            const category = authorizationMatrix?.find(
                (auth: any) => auth.authorization_condition === 'CATEGORY',
            )?.value
            if (!category?.includes(formik.values.category)) {
                formik.setFieldValue('pricingError', {
                    ...formik.values.pricingError,
                    category: {
                        error: true,
                        adjustable: true,
                        useTable: false,
                    },
                })
            } else {
                formik.setFieldValue('pricingError', {
                    ...formik.values.pricingError,
                    category: {
                        error: false,
                        adjustable: true,
                        useTable: false,
                    },
                })
            }
            // if (formik.values.category < minProfitPercentage) {
            //     formik.setFieldValue('pricingError', {
            //         ...formik.values.pricingError,
            //         category: true,
            //     })
            // }
        }
    }, [formik.values.category, authorizationMatrix])

    useEffect(() => {
        if (formik.values.discountServicePreventiveMaintenancePercentage) {
            const maxDiscountForServicePM = parseFloat(
                authorizationMatrix?.find(
                    (auth: any) => auth.authorization_condition === 'DISCOUNT_PM',
                )?.max_value,
            )
            if (
                formik.values.discountServicePreventiveMaintenancePercentage >
                parseFloat(String(maxDiscountForServicePM))
            ) {
                formik.setFieldValue('pricingError', {
                    ...formik.values.pricingError,
                    discountPm: {
                        error: true,
                        adjustable: false,
                        useTable: false,
                    },
                })
            } else {
                formik.setFieldValue('pricingError', {
                    ...formik.values.pricingError,
                    discountPm: {
                        error: false,
                        adjustable: false,
                        useTable: false,
                    },
                })
            }
        }
    }, [formik.values.discountServicePreventiveMaintenancePercentage, authorizationMatrix])

    useEffect(() => {
        const discountForServiceAllIn = authorizationMatrix?.filter(
            (auth: any) => auth.authorization_condition === 'DISCOUNT_ALL_IN',
        )
        if (userExtra.role_id === ROLE_TYPE.fleet) {
            if (formik.values.category === 1 || formik.values.category === 2) {
                if (
                    formik.values.profitPercentage >
                    discountForServiceAllIn.find(
                        (allIn: any) => allIn.extra_condition === '1_2_category,less_than',
                    )?.max_value
                ) {
                    formik.setFieldValue('pricingError', {
                        ...formik.values.pricingError,
                        discountAllIn: {
                            error: true,
                        },
                    })
                } else {
                    formik.setFieldValue('pricingError', {
                        ...formik.values.pricingError,
                        discountAllIn: {
                            error: false,
                        },
                    })
                }
            } else {
                // eslint-disable-next-line no-lonely-if
                if (
                    formik.values.profitPercentage >
                    discountForServiceAllIn.find(
                        (allIn: any) => allIn.extra_condition === '3_category,less_than',
                    )?.max_value
                ) {
                    formik.setFieldValue('pricingError', {
                        ...formik.values.pricingError,
                        discountAllIn: {
                            error: true,
                        },
                    })
                } else {
                    formik.setFieldValue('pricingError', {
                        ...formik.values.pricingError,
                        discountAllIn: {
                            error: false,
                        },
                    })
                }
            }
        } else {
            const tempDiscountServiceAllIn = discountForServiceAllIn.find(
                (allIn: any) =>
                    allIn?.min_value < formik.values.profitPercentage &&
                    allIn?.max_value >= formik.values.profitPercentage,
            )?.value
            if (tempDiscountServiceAllIn?.value === formik.values.discountServiceAllInPercentage) {
                formik.setFieldValue('pricingError', {
                    ...formik.values.pricingError,
                    discountAllIn: {
                        error: true,
                    },
                })
            } else {
                formik.setFieldValue('pricingError', {
                    ...formik.values.pricingError,
                    discountAllIn: {
                        error: false,
                    },
                })
            }
        }
    }, [
        formik.values.profitPercentage,
        formik.values.category,
        formik.values.discountServiceAllInPercentage,
        authorizationMatrix,
    ])

    const checkIfPmPlusAvailable = useMutation(
        ['checkIfPmPlusAvailable'],
        (seriesCode: string) => PricingQueries.isPmPlusAvailable(seriesCode),
        {
            onSuccess: data => {
                if (data?.data?.data) {
                    setIsPmPlusAvailable(true)
                }
            },
            onError: error => {
                toast.error(t('message.check_pm_plus_failed'))
            },
        },
    )

    const handleAddResidualValue = () => {
        formik.setFieldValue(
            'residualValue',
            formik.values.residualValue.concat(initialResidualValue),
        )
    }

    const {
        isLoading: additionalDataLoading,
        refetch: refetchAdditionalData,
        isFetching: additionalDataFetching,
    } = useQuery(
        ['additionalData'],
        () => PricingQueries.getAdditionalConfigurationData(parseInt(String(configurationId), 10)),
        {
            enabled: !!(isInitialFetched && !formik.values.siteSurveyId),
            onSuccess: data => {
                if (!formik.values.siteSurveyId && data?.data?.data) {
                    setIsAdditionalDataFetched(true)
                    const pricingData = data?.data?.data
                    formik.setFieldValue('financing', pricingData?.financing)
                    formik.setFieldValue('maintenance', pricingData?.maintenance)
                    formik.setFieldValue('insurance', pricingData?.insurance)
                    formik.setFieldValue('return', pricingData?.return)
                    formik.setFieldValue('runningHours', pricingData?.running_hours)
                    formik.setFieldValue('returnDescription', pricingData?.return_description || '')
                    formik.setFieldValue(
                        'showAllPriceSeparate',
                        pricingData.show_all_prices_separate,
                    )
                    formik.setFieldValue('category', pricingData.theoretical_category)
                }
            },
        },
    )

    const {
        data: initialPricingData,
        isLoading: initialPricingLoading,
        isFetching: initialPricingFetching,
        refetch: refetchInitialData,
    } = useQuery(
        ['getPricingInitialData'],
        () => PricingQueries.getInitialValueForPricing(parseInt(String(configurationId), 10)),
        {
            enabled:
                !!configurationId &&
                !isInitialFetched &&
                !isRefetching &&
                (quoteDetail?.need_update
                    ? quoteDetail?.need_update && recalculateQuoteMutation.isSuccess
                    : true),
            refetchIntervalInBackground: false,
            refetchInterval: false,
            onSuccess: (d: any) => {
                const initialData = d.data.data
                checkIfPmPlusAvailable.mutate(initialData?.novo_configuration_site_survey?.series)
                setIsBigTruck(initialData.is_big_truck)
                formik.setFieldValue(
                    'isElectric',
                    initialData?.novo_configuration_site_survey?.is_electric,
                )
                const investGroupOption: Options[] = initialData.investment_group
                    ? initialData.investment_group.map((investmentGroup: any) => ({
                          label: `${numberFormater(investmentGroup?.min_value)} ${
                              investmentGroup?.max_value === 0
                                  ? ' +'
                                  : ` - ${numberFormater(investmentGroup?.max_value)}`
                          }`,
                          value: parseFloat(String(investmentGroup?.min_value)),
                      }))
                    : []

                setInvestmentGroupOptions(investGroupOption)
                setPartData(initialData.parts_data)
                setOptions(initialData.lease_price_options)
                let totalOption = 0
                initialData?.lease_price_options.forEach((option: any) => {
                    totalOption += parseFloat(String(option.price))
                })
                setOptionalAttachment(
                    initialData?.extra_service?.attachment?.filter(
                        (attachment: any) => attachment.order_type === ORDER_TYPE.OPTIONAL,
                    ),
                )
                setOptionalBatteries(
                    initialData?.extra_service?.batteries?.filter(
                        (battery: any) => battery.order_type === ORDER_TYPE.OPTIONAL,
                    ),
                )
                setOptionalBatteryAccessories(
                    initialData?.extra_service?.battery_accessories?.filter(
                        (batteryAccessory: any) =>
                            batteryAccessory.order_type === ORDER_TYPE.OPTIONAL,
                    ),
                )
                setOptionalChargers(
                    initialData?.extra_service?.chargers?.filter(
                        (charger: any) => charger.order_type === ORDER_TYPE.OPTIONAL,
                    ),
                )
                setOptionalExSolution(
                    initialData?.extra_service?.ex_solution?.filter(
                        (exSolution: any) => exSolution.order_type === ORDER_TYPE.OPTIONAL,
                    ),
                )
                setOptionalPrepList(
                    initialData?.extra_service?.prep_lists?.filter(
                        (exSolution: any) => exSolution.order_type === ORDER_TYPE.OPTIONAL,
                    ),
                )
                setOptionalBatteryAccessories(
                    initialData?.extra_service?.hyster_batteries?.filter(
                        (hysterBattery: any) => hysterBattery.order_type === ORDER_TYPE.OPTIONAL,
                    ),
                )
                setOptionalSpecial(
                    initialData?.extra_service?.special?.filter(
                        (special: any) => special.order_type === ORDER_TYPE.OPTIONAL,
                    ),
                )
                setExtraPricing({
                    attachment: initialData?.extra_service?.attachment?.filter(
                        (attachment: any) => attachment.order_type === ORDER_TYPE.COMPULSORY,
                    ),
                    batteries: initialData?.extra_service?.batteries?.filter(
                        (battery: any) => battery.order_type === ORDER_TYPE.COMPULSORY,
                    ),
                    batteryAccessories: initialData?.extra_service?.battery_accessories?.filter(
                        (batteryAccessory: any) =>
                            batteryAccessory.order_type === ORDER_TYPE.COMPULSORY,
                    ),
                    chargers: initialData?.extra_service?.chargers?.filter(
                        (charger: any) => charger.order_type === ORDER_TYPE.COMPULSORY,
                    ),
                    exSolution: initialData?.extra_service?.ex_solution?.filter(
                        (exSolution: any) => exSolution.order_type === ORDER_TYPE.COMPULSORY,
                    ),
                    prepList: initialData?.extra_service?.prep_lists?.filter(
                        (prepList: any) => prepList.order_type === ORDER_TYPE.COMPULSORY,
                    ),
                    hysterBatteries: initialData?.extra_service?.hyster_batteries?.filter(
                        (hysterBattery: any) => hysterBattery.order_type === ORDER_TYPE.COMPULSORY,
                    ),
                    special: initialData?.extra_service?.special?.filter(
                        (special: any) => special.order_type === ORDER_TYPE.COMPULSORY,
                    ),
                })
                formik.setFieldValue('totalOptions', totalOption)
                const siteSurveyId = initialData.novo_configuration_site_survey.site_survey?.id
                if (siteSurveyId) {
                    const financing =
                        initialData.novo_configuration_site_survey.site_survey?.financing
                    const maintenance =
                        initialData.novo_configuration_site_survey.site_survey?.maintenance
                    const insurance =
                        initialData.novo_configuration_site_survey.site_survey?.insurance
                    const returnValue =
                        initialData.novo_configuration_site_survey.site_survey?.return
                    const showAllPricesSeparate =
                        initialData.novo_configuration_site_survey.site_survey
                            ?.show_all_prices_separate
                    const returnDescription =
                        initialData.novo_configuration_site_survey.site_survey?.return_description
                    const durationOfContract =
                        initialData.novo_configuration_site_survey.site_survey?.duration_of_contract
                    const runningHours =
                        initialData.novo_configuration_site_survey.site_survey?.running_hours
                    formik.setFieldValue('siteSurveyId', siteSurveyId)
                    formik.setFieldValue('financing', financing)
                    formik.setFieldValue('maintenance', maintenance)
                    formik.setFieldValue('return', returnValue)
                    formik.setFieldValue('returnDescription', returnDescription)
                    formik.setFieldValue('showAllPriceSeparate', showAllPricesSeparate)
                    formik.setFieldValue('insurance', insurance)
                    formik.setFieldValue('durationOfContract', durationOfContract)
                    formik.setFieldValue('runningHours', runningHours)
                    formik.setFieldValue(
                        'tyreType',
                        initialData.novo_configuration_site_survey.site_survey?.type_banden ||
                            'single_std',
                    )
                }
                if (
                    parseInt(String(pricingStatus), 10) === PRICING_STATUS.DRAFT ||
                    parseInt(String(pricingStatus), 10) === PRICING_STATUS.NEW
                ) {
                    formik.setFieldValue('machineListPrice', initialData.machine_list_price)
                    formik.setFieldValue('totalNetValueParts', initialData.total_net_value_parts)
                    formik.setFieldValue('totalOptionsSelected', initialData.total_options_selected)
                    const totalCostPrice = parseFloat(String(initialData.list_price))
                    const sprDiscount = initialData.special_price_rate
                        ? initialData.special_price_rate
                        : initialData.standard_discount
                    const standardDiscount = initialData.standard_discount
                    const totalDiscount =
                        sprDiscount > standardDiscount ? sprDiscount : standardDiscount

                    const vpg = initialData?.vpg ? parseFloat(String(initialData.vpg)) : 0
                    // const quotePrice = parseFloat(String(initialData.total_quote))
                    if (siteSurveyId) {
                        const category =
                            initialData.novo_configuration_site_survey.site_survey?.category
                        formik.setFieldValue('category', category)
                    }
                    const dataLogger = initialData.datalogger

                    formik.setFieldValue('totalCostPrice', totalCostPrice)
                    formik.setFieldValue('vpg', vpg)

                    formik.setFieldValue('specialPriceRequestPercentage', parseFloat(sprDiscount))
                    formik.setFieldValue('standardDiscountRate', parseFloat(standardDiscount))
                    // formik.setFieldValue('quotePrice', quotePrice)
                    formik.setFieldValue('dataLogger', dataLogger)
                }
                setIsInitialFetched(true)
            },
        },
    )

    const canUseBigTruckCalculation: boolean = isBigTruck
        ? true
        : Boolean(formik.values.isFleetRequested)

    const { refetch: refetchCalculateResidualValue, isLoading: residualValueLoading } = useQuery(
        [
            'getFactorResidualValue',
            formik.values.residualValue,
            formik.values.investmentGroup,
            formik.values.quotePrice,
            formik.values.showManualSalesPrice,
            formik.values.salesPrice,
            formik.values.financing,
            formik.values.quoteMinusTradeIn,
            formik.values.discountServiceAllInPercentage,
            formik.values.discountServicePreventiveMaintenancePercentage,
            formik.values.addTyre,
            formik.values.tyreType,
            formik.values.addBattery,
            formik.values.addAttachment,
            formik.values.addSpecials,
            formik.values.addExSolution,
            formik.values.batteryService,
            formik.values.bmwt,
            formik.values.bmwtBattery,
            formik.values.isWhe,
            formik.values.marginForOption,
            formik.values.numberOfBatteries,
            formik.values.additionalResidualPercentage,
            formik.values.useQuotePriceMinusTradeInForCalculation,
            configurationId,
            isInitialFetched,
            isPricingFetched,
        ],
        () =>
            PricingQueries.getResidualAndFinancialPart(parseInt(String(configurationId), 10), {
                months: String(
                    formik.values.residualValue.map((value: any) => value.durationOfContract),
                ),
                additional_residual_percentage: formik.values.additionalResidualPercentage,
                min_investment_group: parseInt(String(formik.values.investmentGroup), 10) || 0,
                quote_total:
                    (formik.values.financing === 'Purchase' ||
                        formik.values.financing === 'Operational Lease and Purchase') &&
                    formik.values.showManualSalesPrice
                        ? formik.values.salesPrice || 0
                        : formik.values.useQuotePriceMinusTradeInForCalculation
                        ? formik.values.quoteMinusTradeIn
                        : formik.values.quotePrice,
                trade_in_amount: formik.values.tradeInValue,
                service_discount_all_in: formik.values.discountServiceAllInPercentage || 0,
                service_discount_pm:
                    formik.values.discountServicePreventiveMaintenancePercentage || 0,
                include_service_tyre: formik.values.addTyre,
                include_service_battery: formik.values.addBattery,
                include_service_attachment: formik.values.addAttachment,
                include_service_specials: formik.values.addSpecials,
                number_of_batteries: formik.values.numberOfBatteries,
                include_service_exsolution: formik.values.addExSolution,
                quantity_battery_service_job: formik.values.batteryService,
                bmwt_selected: formik.values.bmwt,
                bmwt_battery_selected: formik.values.bmwtBattery,
                tyre_type: formik.values.tyreType,
                batteries_selected: formik.values.addBattery,
                is_whe: formik.values.isWhe || false,
                margin_for_options: formik.values.marginForOption,
            }),
        {
            refetchOnMount: false,
            refetchIntervalInBackground: false,
            refetchInterval: false,
            retryOnMount: false,
            refetchOnWindowFocus: false,
            enabled: Boolean(
                !isBigTruck &&
                    configurationId &&
                    (isInitialFetched || isPricingFetched) &&
                    formik.values.residualValue &&
                    !formik.values.residualValue.find(
                        residualValue => residualValue.durationOfContract === 0,
                    ) &&
                    formik.values.investmentGroup >= 0,
            ),
            onSuccess: d => {
                if (d?.data?.data && !canUseBigTruckCalculation) {
                    const residualValueData = d?.data?.data
                    formik.setFieldValue(
                        'residualValue',
                        sortBy(residualValueData, 'month')?.map((value: any) => {
                            setOptions(value.lease_price_options)
                            let totalOption = 0
                            if (value.lease_price_options) {
                                value.lease_price_options.forEach((option: any) => {
                                    totalOption += parseFloat(String(option.price))
                                })
                            }
                            formik.setFieldValue('totalOptions', totalOption)
                            return {
                                durationOfContract: value.month,
                                standardResidualValue:
                                    parseFloat(String(value.standard_residual_value)) || 0,
                                totalResidualValue:
                                    parseFloat(String(value.total_residual_value)) || 0,
                                factor: parseFloat(String(value.factor)) || 0,
                                financialPart: value.financial_part,
                                insuranceCost: value.insurance_cost,
                                serviceBigTruckAllIn: value?.service_big_truck_all_in || 0,
                                serviceBigTruckPm: value?.service_big_truck_pm || 0,
                                serviceAllIn: value.service_all_in,
                                servicePm: value.service_pm,
                                servicePriceTriesPerHour:
                                    parseFloat(String(value.service_big_truck_tyre_per_hour)) || 0,
                                exSolutionServicePrice: value.ex_solution_service_price,
                                filledInsuranceCost: value.filled_insurance_rate,
                                filledPreventiveMaintenance: value.filled_preventive_maintenance,
                                error: value.errors,
                                errorKey: value.error_key,
                                leasePriceOptions: value.lease_price_options,
                            }
                        }),
                    )
                } else {
                    const residualValueData = d?.data?.data
                    setReferenceResidualValue(
                        sortBy(residualValueData, 'month')?.map((value: any) => {
                            return {
                                durationOfContract: value.month,
                                standardResidualValue:
                                    parseFloat(String(value.standard_residual_value)) || 0,
                                totalResidualValue:
                                    parseFloat(String(value.total_residual_value)) || 0,
                                factor: parseFloat(String(value.factor)) || 0,
                                financialPart: value.financial_part,
                                insuranceCost: value.insurance_cost,
                                serviceBigTruckAllIn: value?.service_big_truck_all_in || 0,
                                serviceBigTruckPm: value?.service_big_truck_pm || 0,
                                serviceAllIn: value.service_all_in,
                                servicePm: value.service_pm,
                                servicePriceTriesPerHour:
                                    parseFloat(String(value.service_big_truck_tyre_per_hour)) || 0,
                                exSolutionServicePrice: value.ex_solution_service_price,
                                filledInsuranceCost: value.filled_insurance_rate,
                                filledPreventiveMaintenance: value.filled_preventive_maintenance,
                                error: value.errors,
                                errorKey: value.error_key,
                                leasePriceOptions: value.lease_price_options,
                            }
                        }),
                    )
                }
            },
        },
    )

    const { refetch: refetchCalculateResidualValueBigTruck } = useQuery(
        [
            'getFactorResidualValueTruck',
            formik.values.residualValue,
            formik.values.investmentGroup,
            formik.values.quotePrice,
            formik.values.discountServiceAllInPercentage,
            formik.values.addTyre,
            formik.values.addBattery,
            formik.values.addAttachment,
            formik.values.salesPrice,
            formik.values.financing,
            formik.values.showManualSalesPrice,
            formik.values.addSpecials,
            formik.values.addExSolution,
            formik.values.batteryService,
            formik.values.tyreType,
            formik.values.bmwt,
            formik.values.bmwtBattery,
            formik.values.isWhe,
            formik.values.marginForOption,
            formik.values.numberOfBatteries,
            formik.values.additionalResidualPercentage,
            formik.values.discountServicePreventiveMaintenancePercentage,
            formik.values.useQuotePriceMinusTradeInForCalculation,
            formik.values.quoteMinusTradeIn,
            configurationId,
            isInitialFetched,
            isPricingFetched,
        ],
        () =>
            PricingQueries.getResidualAndFinancialPartBigTruck(
                parseInt(String(configurationId), 10),
                formik.values.residualValue.map(residualValue => ({
                    months: residualValue.durationOfContract,
                    standard_residual_value: residualValue.standardResidualValue || 0,
                    additional_residual_percentage: formik.values.additionalResidualPercentage,
                    factor: residualValue.factor,
                    service_price_all: residualValue.serviceAllIn,
                    service_price_pm: residualValue.servicePm,
                    min_investment_group: parseInt(String(formik.values.investmentGroup), 10) || 0,
                    trade_in_amount: formik.values.tradeInValue,
                    quote_total:
                        (formik.values.financing === 'Purchase' ||
                            formik.values.financing === 'Operational Lease and Purchase') &&
                        formik.values.showManualSalesPrice
                            ? formik.values.salesPrice || 0
                            : formik.values.useQuotePriceMinusTradeInForCalculation
                            ? formik.values.quoteMinusTradeIn
                            : formik.values.quotePrice,
                    service_big_truck_all_in: residualValue.serviceBigTruckAllIn || 0,
                    service_big_truck_pm: residualValue.serviceBigTruckPm || 0,
                    ex_solution_service_price: residualValue.exSolutionServicePrice || 0,
                    filled_insurance_rate: residualValue.filledInsuranceCost || 0,
                    filled_preventive_maintenance: residualValue.filledPreventiveMaintenance || 0,
                    service_discount_all_in: formik.values.discountServiceAllInPercentage || 0,
                    service_discount_pm:
                        formik.values.discountServicePreventiveMaintenancePercentage || 0,
                    include_service_tyre: formik.values.addTyre,
                    include_service_battery: formik.values.addBattery,
                    number_of_batteries: formik.values.numberOfBatteries,
                    include_service_attachment: formik.values.addAttachment,
                    tyre_type: formik.values.tyreType,
                    service_big_truck_tyre_per_hour: residualValue.servicePriceTriesPerHour,
                    include_service_specials: formik.values.addSpecials,
                    include_service_exsolution: formik.values.addExSolution,
                    quantity_battery_service_job: formik.values.batteryService,
                    bmwt_selected: formik.values.bmwt,
                    bmwt_battery_selected: formik.values.bmwtBattery,
                    batteries_selected: formik.values.addBattery,
                    is_whe: formik.values.isWhe || false,
                    margin_for_options: formik.values.marginForOption,
                })),
            ),
        {
            refetchOnMount: false,
            refetchIntervalInBackground: false,
            refetchInterval: false,
            retryOnMount: false,
            refetchOnWindowFocus: false,
            enabled: Boolean(
                canUseBigTruckCalculation &&
                    configurationId &&
                    formik.values.residualValue &&
                    (isInitialFetched || isPricingFetched) &&
                    !formik.values.residualValue.some(item => item.durationOfContract === 0) &&
                    formik.values.investmentGroup >= 0,
            ),
            onSuccess: d => {
                if (d?.data?.data && canUseBigTruckCalculation) {
                    const residualValueData = d?.data?.data
                    formik.setFieldValue(
                        'residualValue',
                        sortBy(residualValueData, 'month')?.map((value: any) => {
                            setOptions(value.lease_price_options)
                            let totalOption = 0
                            if (value.lease_price_options) {
                                value.lease_price_options.forEach((option: any) => {
                                    totalOption += parseFloat(String(option.price))
                                })
                            }
                            formik.setFieldValue('totalOptions', totalOption)
                            return {
                                durationOfContract: value.month,
                                standardResidualValue:
                                    parseFloat(String(value.standard_residual_value)) || 0,
                                totalResidualValue:
                                    parseFloat(String(value.total_residual_value)) || 0,
                                factor: parseFloat(String(value.factor)) || 0,
                                financialPart: value.financial_part,
                                servicePriceTriesPerHour: parseFloat(
                                    String(value.service_big_truck_tyre_per_hour),
                                ),
                                exSolutionServicePrice: value.ex_solution_service_price || 0,
                                filledInsuranceCost: value.filled_insurance_rate || 0,
                                filledPreventiveMaintenance:
                                    value.filled_preventive_maintenance || 0,
                                insuranceCost: value.insurance_cost,
                                serviceBigTruckAllIn: value?.service_big_truck_all_in || 0,
                                serviceBigTruckPm: value?.service_big_truck_pm || 0,
                                serviceAllIn: value.service_all_in,
                                servicePm: value.service_pm,
                                error: value.errors,
                                errorKey: value.error_key,
                                leasePriceOptions: value.lease_price_options,
                            }
                        }),
                    )
                }
            },
            onError: (error: Error) => {
                toast.error(error.message || t('message.fetch_calculation_error'))
            },
        },
    )

    useEffect(() => {
        formik.setFieldValue(
            'quoteMinusTradeIn',
            formik.values.quotePrice - formik.values.tradeInValue,
        )
    }, [formik.values.quotePrice, formik.values.tradeInValue])

    const {
        isLoading: pricingByConfigurationIdLoading,
        isFetching: pricingByConfigurationIdFetching,
        refetch: refetchPricingData,
    } = useQuery(
        ['getCompanyRates'],
        () => PricingQueries.getPricingForConfigurationId(parseInt(String(configurationId), 10)),
        {
            enabled: Boolean(
                !!configurationId &&
                    isInitialFetched &&
                    !isPricingFetched &&
                    parseInt(String(pricingStatus), 10) > PRICING_STATUS.NEW,
            ),
            onSuccess: async data => {
                const pricingData = data?.data?.data
                setConfigurationPriceId(pricingData.id)
                formik.setFieldValue('machineListPrice', pricingData.machine_list_price)
                formik.setFieldValue('totalNetValueParts', pricingData.total_net_value_parts)
                formik.setFieldValue('totalOptionsSelected', pricingData.total_options_selected)
                formik.setFieldValue(
                    'totalCostPrice',
                    parseFloat(String(pricingData.novo_list_price)),
                )
                formik.setFieldValue(
                    'specialPriceRequestPercentage',
                    parseFloat(String(pricingData.special_price_request_percentage)),
                )
                formik.setFieldValue(
                    'standardDiscountRate',
                    parseFloat(String(pricingData.standard_discount_percentage)),
                )
                formik.setFieldValue('vpg', parseFloat(String(pricingData.vpg_percentage)))
                formik.setFieldValue('vpgAmount', parseFloat(String(pricingData.vpg_amount)))
                formik.setFieldValue(
                    'bridgingMachineAmount',
                    parseFloat(String(pricingData.bridging_machine_amount)),
                )
                formik.setFieldValue(
                    'financialSupportAmount',
                    parseFloat(String(pricingData.financial_support_amount)),
                )
                formik.setFieldValue('dataLogger', parseFloat(String(pricingData.data_logger)))
                formik.setFieldValue('netAllCost', parseFloat(String(pricingData.net_all_cost)))
                formik.setFieldValue(
                    'profitPercentage',
                    parseFloat(String(pricingData.profit_percentage)),
                )
                formik.setFieldValue('profitAmount', parseFloat(String(pricingData.profit_amount)))
                formik.setFieldValue('tradeInValue', parseFloat(String(pricingData.trade_in_value)))
                formik.setFieldValue(
                    'tradeInValueTextField',
                    pricingData.trade_in_value_text_field || '',
                )
                formik.setFieldValue(
                    'show_trade_in_value_text_field',
                    Boolean(pricingData.show_trade_in_value_text_field),
                )
                formik.setFieldValue(
                    'useQuotePriceMinusTradeInForCalculation',
                    pricingData.use_quote_price_minus_trade_in_for_calculation,
                )
                if (pricingData.trade_in_value) {
                    formik.setFieldValue('showTradeIn', true)
                }
                if (pricingData.quote_minus_trade_in) {
                    formik.setFieldValue('quoteMinusTradeIn', pricingData.quote_minus_trade_in)
                }
                formik.setFieldValue(
                    'additionalResidualPercentage',
                    parseFloat(String(pricingData.additional_residual_percentage)),
                )
                formik.setFieldValue(
                    'salesPrice',
                    pricingData?.manual_price
                        ? parseFloat(String(pricingData?.manual_price))
                        : null,
                )
                formik.setFieldValue('showManualSalesPrice', !!pricingData?.manual_price)
                formik.setFieldValue(
                    'investmentGroup',
                    parseFloat(String(pricingData.min_investment_group)),
                )
                formik.setFieldValue(
                    'discountServiceAllInPercentage',
                    parseFloat(String(pricingData.discount_service_all_in)),
                )
                formik.setFieldValue(
                    'discountServicePreventiveMaintenancePercentage',
                    parseFloat(String(pricingData.discount_service_pm)),
                )
                formik.setFieldValue(
                    'selectedDurationOfContract',
                    pricingData.selected_duration_of_contract,
                )
                // formik.setFieldValue('runningHours', pricingData.running_hours)
                formik.setFieldValue('addBattery', pricingData.add_battery)
                formik.setFieldValue('addAttachment', pricingData.add_attachment)
                formik.setFieldValue('addSpecials', pricingData.add_specials)
                formik.setFieldValue(
                    'addTyre',
                    canUseBigTruckCalculation ? true : pricingData.add_tyre,
                )
                formik.setFieldValue('bmwt', pricingData.bmwt)
                formik.setFieldValue('isWhe', pricingData.is_whe)
                formik.setFieldValue('bmwtBattery', pricingData.add_bmwt_battery)
                formik.setFieldValue('addExSolution', Boolean(pricingData.add_ex_solutions))
                formik.setFieldValue('tyreType', pricingData.type_banden || 'single_std')
                formik.setFieldValue('category', pricingData.category)
                formik.setFieldValue('batteryService', pricingData.battery_service)
                formik.setFieldValue('isFleetRequested', pricingData.is_fleet_requested)
                if (pricingData?.margin_for_options) {
                    formik.setFieldValue(
                        'marginForOption',
                        parseFloat(pricingData.margin_for_options),
                    )
                }
                formik.setFieldValue(
                    'numberOfBatteries',
                    parseFloat(String(pricingData?.number_of_batteries)),
                )
                if (pricingData.monthly_configuration_price.length > 0) {
                    setInitialConfigurationMonths(pricingData.monthly_configuration_price)
                    const residualValue: IResidualValue =
                        pricingData.monthly_configuration_price.map((value: any) => ({
                            durationOfContract: value.duration_of_contract,
                            standardResidualValue: parseFloat(
                                String(value.standard_residual_value),
                            ),
                            totalResidualValue: parseFloat(String(value.total_residual_value)),
                            serviceBigTruckAllIn: value.service_price_big_truck_all_in || 0,
                            serviceBigTruckPm: value.service_price_big_truck_pm || 0,
                            factor: value.lease_factor,
                            financialPart: value.financial_part,
                            insuranceCost: value.insurance_cost,
                            error: value.errors,
                            errorKey: value.error_key,
                            servicePriceTriesPerHour: parseFloat(
                                String(value.service_big_truck_tyre_per_hour),
                            ),
                            exSolutionServicePrice: value.ex_solution_service_price,
                            filledInsuranceCost: value.filled_insurance_rate,
                            filledPreventiveMaintenance: value.filled_preventive_maintenance,
                            leasePriceOptions: JSON.parse(value.lease_price_options),
                            serviceAllIn: {
                                basic_truck: value.basic_truck,
                                service_extra_preplist: value.service_extra_preplist,
                                service_extra_attachments: value.service_extra_attachments,
                                service_tyre: value.service_tyre,
                                service_extra_special: parseFloat(
                                    String(value.service_extra_special),
                                ),
                                service_battery: value.service_battery,
                                service_ex_solutions: 0,
                                total_service: 0,
                            },
                            servicePm: {
                                batteries_service_cost: value.batteries_service_cost,
                                preventive_maintenance: value.preventive_maintenance,
                                total_price_per_year_ex_bmwt: value.total_price_per_year_ex_bmwt,
                                total_price_per_year_inclusive_bmwt:
                                    value.total_price_per_year_inclusive_bmwt,
                            },
                        }))
                    await formik.setFieldValue(
                        'residualValue',
                        sortBy(residualValue, 'durationOfContract'),
                    )
                }

                setIsPricingFetched(true)
            },
            onError: (error: any) => {
                if (error?.message !== 'Configuration Not found') {
                    toast.error(error?.message || t('message.get_pricing_failed'))
                }
            },
        },
    )

    const updateDurationOfContract = (value: number, index: number) => {
        const doesExist = !formik.values.residualValue.find(r => r.durationOfContract === value)
        if (!value) {
            toast.error(t('message.select_valid_duration_of_contract'))
            return
        }
        if (doesExist) {
            const { residualValue } = formik.values
            const updatedValue = { ...residualValue[index] }
            updatedValue.durationOfContract = value
            residualValue[index] = updatedValue
            if (residualValue.length === 1) {
                formik.setFieldValue('selectedDurationOfContract', value)
            }
            formik.setFieldValue('residualValue', residualValue)
        } else {
            toast.error(t('message.duration_of_contract_already_exist'))
        }
    }

    const updateStandardResidualValue = (value: number, index: number) => {
        const { residualValue } = formik.values
        const updatedValue = { ...residualValue[index] }
        updatedValue.standardResidualValue = value
        residualValue[index] = updatedValue
        formik.setFieldValue('residualValue', residualValue)
    }

    const updateTotalResidualValue = (value: number, index: number) => {
        const { residualValue } = formik.values
        const updatedValue = { ...residualValue[index] }
        updatedValue.totalResidualValue = value
        residualValue[index] = updatedValue
        formik.setFieldValue('residualValue', residualValue)
    }

    const updateLeaseFactor = (value: number, index: number) => {
        const { residualValue } = formik.values
        const updatedValue = { ...residualValue[index] }
        updatedValue.factor = value
        residualValue[index] = updatedValue
        formik.setFieldValue('residualValue', residualValue)
    }

    const updateServiceBigTruckAllIn = (value: number, index: number) => {
        const { residualValue } = formik.values
        const updatedValue = { ...residualValue[index] }
        updatedValue.serviceBigTruckAllIn = value
        residualValue[index] = updatedValue
        formik.setFieldValue('residualValue', residualValue)
    }

    const updateServiceBigTruckPm = (value: number, index: number) => {
        const { residualValue } = formik.values
        const updatedValue = { ...residualValue[index] }
        updatedValue.serviceBigTruckPm = value
        residualValue[index] = updatedValue
        formik.setFieldValue('residualValue', residualValue)
    }

    const updateServicePriceTyrePerHourBigTruck = (value: number, index: number) => {
        const { residualValue } = formik.values
        const updatedValue = { ...residualValue[index] }
        updatedValue.servicePriceTriesPerHour = value
        residualValue[index] = updatedValue
        formik.setFieldValue('residualValue', residualValue)
    }

    const updateExSolutionServicePrice = (value: number, index: number) => {
        const { residualValue } = formik.values
        const updatedValue = { ...residualValue[index] }
        updatedValue.exSolutionServicePrice = value
        residualValue[index] = updatedValue
        formik.setFieldValue('residualValue', residualValue)
    }

    const updatePreventiveMaintenance = (value: number, index: number) => {
        const { residualValue } = formik.values
        const updatedValue = { ...residualValue[index] }
        updatedValue.filledPreventiveMaintenance = value
        residualValue[index] = updatedValue
        formik.setFieldValue('residualValue', residualValue)
    }

    const updateInsuranceCost = (value: number, index: number) => {
        const { residualValue } = formik.values
        const updatedValue = { ...residualValue[index] }
        updatedValue.filledInsuranceCost = value
        residualValue[index] = updatedValue
        formik.setFieldValue('residualValue', residualValue)
    }

    useEffect(() => {
        let discountAmount = 0
        if (formik.values.specialPriceRequestPercentage) {
            discountAmount =
                (formik.values.machineListPrice * formik.values.specialPriceRequestPercentage) / 100
        } else {
            discountAmount =
                (formik.values.machineListPrice * formik.values.standardDiscountRate) / 100
        }
        formik.setFieldValue('discountAmount', discountAmount)
        const discountedAmount = formik.values.machineListPrice - discountAmount
        const totalCostPriceIncludingDiscount =
            discountedAmount + formik.values.totalNetValueParts - formik.values.totalOptionsSelected
        formik.setFieldValue('totalCostPriceIncludingDiscount', totalCostPriceIncludingDiscount)
        formik.setFieldValue(
            'vpgAmount',
            totalCostPriceIncludingDiscount * (formik.values.vpg / 100),
        )
    }, [
        formik.values.standardDiscountRate,
        formik.values.specialPriceRequestPercentage,
        formik.values.vpg,
    ])

    useEffect(() => {
        const netAllCost =
            formik.values.totalCostPriceIncludingDiscount +
            formik.values.vpgAmount +
            parseFloat(String(formik.values.bridgingMachineAmount)) +
            formik.values.financialSupportAmount +
            formik.values.totalExtra +
            // dont add total option
            // formik.values.totalOptions +
            formik.values.dataLogger
        formik.setFieldValue('netAllCost', netAllCost)
    }, [
        formik.values.totalCostPrice,
        formik.values.discountAmount,
        formik.values.vpgAmount,
        formik.values.bridgingMachineAmount,
        formik.values.financialSupportAmount,
        formik.values.totalExtra,
        // formik.values.totalOptions,
        formik.values.dataLogger,
        isPricingFetched,
        isInitialFetched,
    ])

    useEffect(() => {
        if (formik.values.netAllCost) {
            const quotePrice =
                formik.values.netAllCost /
                (1 - parseFloat(String(formik.values.profitPercentage)) / 100)
            const profitAmount = quotePrice - formik.values.netAllCost
            formik.setFieldValue('quotePrice', quotePrice)
            formik.setFieldValue('profitAmount', profitAmount)
        }
    }, [formik.values.profitPercentage, formik.values.netAllCost])

    if (
        initialPricingLoading ||
        initialPricingFetching ||
        pricingByConfigurationIdLoading ||
        pricingByConfigurationIdFetching ||
        additionalDataLoading ||
        additionalDataFetching ||
        isRefetching ||
        recalculateQuoteMutation.isLoading ||
        isSubmitLoading ||
        isSaveSiteSurveyLoading
    ) {
        return (
            <div className="h-full w-full flex justify-center items-center">
                <Loading />
            </div>
        )
    }

    if (!formik.values.siteSurveyId && !isAdditionalDataFetched) {
        return (
            <SiteSurveyNotConnected
                refetchInitial={refetchInitialData}
                refetchAdditionalData={refetchAdditionalData}
            />
        )
    }

    return (
        <div className="h-full w-full flex flex-col gap-4">
            <div className="flex w-full justify-between items-center">
                <p className="text-lg h-fit font-medium underline underline-offset-8 decoration-4 decoration-primary text-bold cursor-pointer hover:border-gray-300 w-full">
                    {t('api.pricing')}
                </p>
            </div>
            <div className="flex flex-col flex-1">
                <Collapse
                    defaultActiveKey={[1, 2, 3]}
                    ghost
                    expandIconPosition="end"
                    className="bg-white w-full"
                >
                    <Panel
                        className="bg-white"
                        header={<p className="text-lg">{t('api.general_pricing')}</p>}
                        key={1}
                    >
                        <GeneralPricing
                            refetchInitial={refetchInitialData}
                            refetchCalculateResidualValueBigTruck={
                                refetchCalculateResidualValueBigTruck
                            }
                            refetchAdditionalData={refetchAdditionalData}
                            refetchCalculateResidualValue={refetchCalculateResidualValue}
                            refetchPricingData={refetchPricingData}
                            initialSiteSurveyData={
                                initialPricingData?.data?.data?.novo_configuration_site_survey
                                    .site_survey
                            }
                            isPmPlusAvailable={isPmPlusAvailable}
                            setLoading={setIsSaveSiteSurveyLoading}
                            isAttachmentSelected={extraPricing.attachment?.length > 0}
                            formik={formik}
                        />
                    </Panel>
                    <Panel
                        className="bg-white"
                        header={<p className="text-lg">{t('api.purchase_price_calculation')}</p>}
                        key={2}
                    >
                        <PurchasePriceCalculation
                            formik={formik}
                            isBigTruck={canUseBigTruckCalculation}
                            extraPricingComponent={extraPricing}
                            totalPreplist={totalPrepList}
                            totalChargers={totalChargers}
                            totalBatteries={totalBatteries}
                            totalBatteryAccessories={totalBatteryAccessories}
                            totalAttachments={totalAttachments}
                            totalExSolutions={totalExSolutions}
                            totalSpecial={totalSpecial}
                            totalExtra={totalExtra}
                            totalHysterBattery={totalHysterBattery}
                            investmentGroupOptions={investGroupOptions}
                            isRequestFleet={isRequestFleet}
                            isRequestFleetLoading={requestForApproval.isLoading}
                            isSaveLoading={savePricing.isLoading}
                            handleRequestFleet={handleRequestFleet}
                            requestFleetRemark={requestFleetRemark}
                            setRequestFleetRemark={setRequestFleetRemark}
                            configurationPriceId={configurationPriceId}
                        />
                    </Panel>
                    <Panel
                        className="bg-white"
                        header={<p className="text-lg">{t('api.pricing_overview')}</p>}
                        key={3}
                    >
                        <PricingOverview
                            formik={formik}
                            isBigTruck={
                                canUseBigTruckCalculation ||
                                Boolean(
                                    userExtra.role_id === ROLE_TYPE.fleet &&
                                        formik.values.residualValue.find((x: any) => x.error),
                                )
                            }
                            configurationPriceId={configurationPriceId}
                            handleAddResidualValue={handleAddResidualValue}
                            updateDurationOfContract={updateDurationOfContract}
                            updateStandardResidualValue={updateStandardResidualValue}
                            updateTotalResidualValue={updateTotalResidualValue}
                            updateLeaseFactor={updateLeaseFactor}
                            updateServiceBigTruckAllIn={updateServiceBigTruckAllIn}
                            updateServiceBigTruckPm={updateServiceBigTruckPm}
                            updateServicePriceTyrePerHourBigTruck={
                                updateServicePriceTyrePerHourBigTruck
                            }
                            updateInsuranceCost={updateInsuranceCost}
                            updatePreventiveMaintenance={updatePreventiveMaintenance}
                            updateExSolutionServicePrice={updateExSolutionServicePrice}
                            monthlyConfigurationPrice={monthlyConfigurationPrice}
                            referenceResidualValue={referenceResidualValue}
                        />
                    </Panel>
                    {(options?.length > 0 ||
                        optionalAttachment?.length > 0 ||
                        optionalBatteries?.length > 0 ||
                        optionalBatteryAccessories?.length > 0 ||
                        optionalChargers?.length > 0 ||
                        optionalExSolution?.length > 0 ||
                        optionalPrepList?.length > 0 ||
                        optionalHysterBatteries?.length > 0 ||
                        optionalSpecial?.length > 0) && (
                        <Panel
                            className="bg-white"
                            header={<p className="text-lg">{t('api.optional')}</p>}
                            key={4}
                        >
                            <OptionPricingComponent
                                options={options}
                                formik={formik}
                                optionalAttachment={optionalAttachment}
                                optionalBatteries={optionalBatteries}
                                optionalBatteryAccessories={optionalBatteryAccessories}
                                optionalChargers={optionalChargers}
                                optionalExSolution={optionalExSolution}
                                optionalPrepList={optionalPrepList}
                                optionalSpecial={optionalSpecial}
                                optionalHysterBatteries={optionalHysterBatteries}
                            />
                        </Panel>
                    )}
                </Collapse>
            </div>
        </div>
    )
}

export default Pricing
